<template>
  <div>
    <div class="app-container">
      <el-form
        :model="dataForm"
        :rules="rules"
        ref="dataForm"
        label-width="120px"
		
         class="form-con-left4"
       
      >
        <el-form-item label="问卷分类" prop="tableModel">
          <el-cascader
            v-model="array"
            :options="menuList"
            :props="defaultProps"
            ref="elcascader"
            @change="handleChange"
            popper-class="theder"
            style="width: 100%"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="表单名称" prop="tableName">
          <el-input
            placeholder="请输入名称"
            v-model="dataForm.tableName"
          ></el-input>
        </el-form-item>
<!--        <el-form-item label="表单权限" prop="authorityType">
          <el-select
            v-model="dataForm.authorityType"
            style="width: 100%"
            clearable
          >
            <el-option :value="1" label="团队内共享">团队内共享</el-option>
            <el-option :value="2" label="仅自己使用">仅自己使用</el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="表单权重">
          <el-input
            placeholder="默认为0，数字越大，排序越靠前"
            type="number"
            v-model="dataForm.orderWeight"
          ></el-input>
        </el-form-item>

        <el-form-item style="padding-top: 30px">
          <!-- 1 -->
          <el-button type="primary" @click="submitSubject(1)">保存</el-button>
          <el-button @click="submitSubject()">保存并配置题目</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import qs from "qs";
import { treeDataTranslate } from "@/utils";
export default {
  data() {
    return {
      type: "", //新建或者复制新建
      libraryId: "", //指标库id
      dialogVisible: false,
      unionId: "",
      array: [],
      dataForm: {
        hospitalId: "", //医院id
        teamId: "", //团队id
        tableName: "", //表单名称
        tableBelongs: 1, //表单权限
        orderWeight: "", //权重
        tableType: "",
        tableModel: "",
        authorityType: 1,
      },
      rules: {
        tableName: [
          { required: true, message: "请输入表单名称", trigger: "blur" },
        ],
        authorityType: [
          {
            required: true,
            message: "请选择表单权限",
            trigger: "change",
          },
        ],
        tableModel: [
          { required: true, message: "请选择问卷分类", trigger: "change" },
        ],
      },
      followUpTypeList: [], //表单类别
      myArray: [], //下级分类
      picUrl: "", //图片地址
      flag: 1, //防止重复点击
      secList: [],
      menuList: [],
      defaultProps: {
        children: "list",
        label: "name",
        expandTrigger: "hover",
        checkStrictly: true,
        emitPath: true,
      },
    };
  },

  async mounted() {
    await this.getDictList();
    if (this.$route.query.id) {
      this.libraryId = this.$route.query.id;
      await this.getLibraryInfo();
    }
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }

    if (localStorage.userInfo) {
      this.unionId = JSON.parse(localStorage.userInfo).doctorUnionId;
    }
  },

  methods: {
    handleChange(node) {
      if (node.length) {
        this.dataForm.tableModel = node[0];
        if (node[1]) {
          this.dataForm.tableType = node[1];
        } else {
          this.dataForm.tableType = "";
        }
      }
    },
    // 获取字典
    async getDictList() {
      let _this = this;

      const { data } = await this.$httpAes({
        url: _this.$httpAes.adornUrl("/dict/getSysDicts?type=" + "表单类别"),
        method: "post",
        data: {
          stringParam1:"表单类别",
          // hospitalId:this.$cookie.get('hospitalId'),
          pageSize: 999,
          pageNo: 1
        },
        contentType:'json',
        headers: {
        	'Content-Type': 'application/json',
           				
        },
   
      });

      if (data.status) {
        _this.menuList = data.data;
        this.changeData(_this.menuList);
        if (this.$route.query.contentType) {
          this.dataForm.tableModel = this.$route.query.contentType;
          this.array[0] = parseInt(this.dataForm.tableModel);
        }
        if (this.$route.query.contentTypeSec) {
          this.dataForm.tableType = this.$route.query.contentTypeSec;
          this.array[1] = this.dataForm.tableType;
        }
      }
    },

    changeData(arr) {
      for (var i = 0; i < arr.length; i++) {
        arr[i].list = arr[i].map.list;
        if (arr[i].map.list != null && arr[i].map.list.length != 0) {
          this.changeData(arr[i].map.list);
        }
        if (arr[i].map.list.length < 1) {
          arr[i].list = undefined;
        }
      }
    },
    //提交指标库
    submitSubject(type) {
      let _this = this;
      if (localStorage.currentTeam) {
        _this.dataForm.teamId = JSON.parse(localStorage.currentTeam).id;
      }
      if (localStorage.userInfo) {
        this.dataForm.hospitalId = JSON.parse(localStorage.userInfo).deptPid;
      }

      if (_this.unionId) {
        _this.dataForm.unionId = _this.unionId;
      }
      let params = JSON.parse(JSON.stringify(_this.dataForm));
      if (_this.dataForm.orderWeight) {
        params.orderWeight = _this.dataForm.orderWeight;
      } else {
        params.orderWeight = 0;
      }

      // console.log(params);
      // return
      _this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          if (_this.flag == 1) {
            _this.flag = 0;
            _this
              .$httpAes({
                url: _this.$httpAes.adornUrl("/vfTableInfo/add"),
                method: "post",
       
				data: params,
				contentType:'json',
				headers: {
					'Content-Type': 'application/json',
				   				
				},
              })
              .then((data) => {
                if (data.data.status) {
                  _this.$message({
                    message: data.data.msg,
                    type: "success",
                    duration: 1500,
                    onClose: () => {
                      _this.flag = 1;
                      if (type == 1) {
                        this.$router.push({ name: "dataCollect" });
                      } else {
                        if (data.data && data.data.data.id) {
                          localStorage.formId = data.data.data.id;
                        }
                        console.log(data);
                        window.open(
                          this.$global.dominUrlvform +
                            "/#/?formId=" +
                            data.data.data.id
                        );
                        setTimeout(() => {
                          this.$router.push({ name: "dataCollect" });
                        }, 1000);
                      }
                    },
                  });
                } else {
                  _this.flag = 1;
                  _this.$message({
                    message: data.data.msg,
                    type: "error",
                    duration: 1500,
                    onClose: () => {},
                  });
                }
              });
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.theder {
  .el-cascader-panel {
    .el-cascader-menu {
      .el-radio {
        height: 100%;
        width: 150px;
        opacity: 0;
        position: absolute;
        // z-index: 10;
        .el-radio__input {
          .el-radio__inner {
            border: 0px;
          }
        }
        .el-radio__input.is-checked {
          .el-radio__inner {
            background: none;
          }
        }
      }
    }
  }
}
.el-input__inner {
  width: 50%;
}
.app-container {
  margin-top: 30px;
}
.type-list {
  width: 50%;
  border: 1px solid #ededed;
  .header {
    width: 100%;
    background: #f2f2f2;
    height: 40px;
    line-height: 40px;
    font-weight: bold;
    overflow: hidden;
    display: flex;
    .item {
      height: 100%;
      text-align: center;
      border-right: 1px solid #eee;
      &:last-child {
        border-right: 0;
      }
    }
  }
  .list-item {
    display: flex;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid #ededed;
    &:last-child {
      border-bottom: none;
    }
    .item {
      border-right: 1px solid #eee;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.radio-item {
  padding-bottom: 10px;
}
</style>
